import AnimatedPage from "../AnimatedPage/AnimatedPage";
import { useContext } from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import Media from "react-media";
import Footer from "../../modules/footer";
import { Helmet } from "react-helmet";

const contactAnimation = require("../../lottieJsons/contactPage.json");

const ContactUs = () => {
    const { theme, setTheme } = useContext(ThemeContext);
    const fontColor = theme === "dark" ? "rgb(231 229 228)" : "black";
    const headingColor = theme === "dark" ? "#E7EBF1" : "#584a89";
    const headingFont = " text-2xl sssm:text-3xl sm:text-3xl ";

    return (
        <>
            <Helmet>
                <title>Noggin – Contact Us</title>
                <meta
                    name="description"
                    content="Interested? Get in touch with us via our email at hello@noggin.ai and/or phone using NovoCall. Contact us with ease."
                />
                <meta
                    name="keywords"
                    content="Noggin Private Limited, Mandai, Kranji"
                />
            </Helmet>
            <AnimatedPage>
                <style>
                    {`.pageContent{
              word-break: break-word;
              color : ${fontColor}
            }`}
                </style>
                <div
                    className="flex flex-col lg:flex-row items-center justify-center lg:mt-[5%] mt-14"
                    style={{ paddingLeft: "10%" }}
                >
                    <div
                        className="w-full lg:w-1/2 flex-initial flex flex-col justify-center items-center"
                        style={{ paddingRight: "5%" }}
                    >
                        <h1
                            className="pageContent pageHeading text-5xl py-5"
                            style={{
                                paddingTop: "15%",
                                color: `${headingColor}`
                            }}
                        >
                            GET IN TOUCH
                            <br />
                            <span
                                className="pageContent pageHeading text-5xl py-5"
                                style={{ color: `${headingColor}` }}
                            >
                                WITH US
                            </span>
                        </h1>
                    </div>
                    <div className="w-full lg:w-1/2 flex-initial">
                        <Media query={{ minWidth: 1024 }}>
                            <Lottie
                                loop={false}
                                animationData={contactAnimation}
                                play
                                style={{
                                    width: 726,
                                    height: 550,
                                    position: "relative",
                                    top: "100px"
                                }}
                            />
                        </Media>
                    </div>
                </div>
                <div style={{ height: "100px" }}></div>
                <div
                    className="rounded-lg shadow-2xl"
                    style={{
                        backgroundColor: "#f5f5f5",
                        marginLeft: "10%",
                        marginRight: "10%"
                    }}
                >
                    <div
                        className="flex flex-col lg:flex-row"
                        style={{ padding: "3%" }}
                    >
                        <div className="flex-initial w-full lg:w-4/12 flex flex-col justify-center text-center lg:text-left">
                            <h1
                                style={{
                                    fontFamily: "Neutro",
                                    paddingTop: "7%",
                                    wordBreak: "break-word",
                                    color: "black"
                                }}
                                className={`${headingFont}`}
                            >
                                Contact Us
                            </h1>
                            <p
                                style={{
                                    fontFamily: "SF Pro",
                                    paddingTop: "5%"
                                }}
                                className="text-lg"
                            >
                                Noggin Private Limited
                                <br />
                                77 High Street, #02-13
                                <br />
                                High Street Plaza
                                <br />
                                Singapore 179433
                            </p>
                            <h1
                                style={{
                                    fontFamily: "SF Pro Bold",
                                    marginTop: "5%"
                                }}
                                className="text-xl md:text-2xl lg:text-2xl"
                            >
                                Nearest MRT Station(s):
                            </h1>
                            <p style={{ fontFamily: "SF Pro" }}>
                                EW13/NS25,{" "}
                                <strong>
                                    <u>City Hall MRT Station</u>
                                </strong>
                            </p>
                            <p style={{ fontFamily: "SF Pro" }}>
                                NE5,{" "}
                                <strong>
                                    <u>Clarke Quay MRT Station</u>
                                </strong>
                            </p>
                            {/* <h1
                                style={{
                                    fontFamily: "SF Pro Bold",
                                    marginTop: "5%"
                                }}
                                className="text-xl md:text-2xl lg:text-2xl"
                            >
                                Nearest Bus Stations:
                            </h1>
                            <p style={{ fontFamily: "SF Pro" }}>
                                <strong>
                                    <u>Bus 160</u>
                                </strong>{" "}
                                and{" "}
                                <strong>
                                    <u>Bus 170</u>
                                </strong>
                                , <br />
                                <strong> 5 stops away </strong> from Kranji
                                Station
                            </p> */}
                            <h1
                                style={{
                                    fontFamily: "SF Pro Bold",
                                    marginTop: "5%"
                                }}
                                className="text-xl md:text-2xl lg:text-2xl"
                            >
                                Email
                            </h1>
                            <a
                                href="mailto:hello@noggin.ai"
                                style={{
                                    fontFamily: "SF Pro",
                                    textDecoration: "underline"
                                }}
                            >
                                <p>hello@noggin.ai</p>
                            </a>
                            {/* <h1
                                style={{
                                    fontFamily: "SF Pro Bold",
                                    marginTop: "5%"
                                }}
                                className="text-xl md:text-2xl lg:text-2xl"
                            >
                                Phone
                            </h1>
                            <p style={{ fontFamily: "SF Pro" }}>
                                <strong>Use NovoCall</strong>
                                <br />
                                <span>
                                    Call us with <strong>ease</strong>
                                </span>
                            </p> */}
                        </div>
                        <div
                            className="w-full lg:w-8/12 border-indigo-300 border-t lg:border-l lg:border-t-0 disable-tablet"
                            style={{ paddingLeft: "10%", padding: "2%" }}
                        >
                            <iframe
                                src="https://www.onemap.gov.sg/minimap/minimap.html?mapStyle=Default&zoomLevel=15&latLng=1.2904540746831,103.849358869172&ewt=JTNDcCUzRSUzQ3N0cm9uZyUzRVdlJTIwYXJlJTIwaGVyZSElM0MlMkZzdHJvbmclM0UlM0MlMkZwJTNF&popupWidth=200&showPopup=true"
                                height="450"
                                width="450"
                                scrolling="no"
                                frameborder="0"
                                allowfullscreen="allowfullscreen"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div style={{ height: "200px" }}></div>
                <Footer />
            </AnimatedPage>
        </>
    );
};

export default ContactUs;
