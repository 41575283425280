import { useContext } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Media from "react-media";

const Footer = () => {
    const { theme, setTheme } = useContext(ThemeContext);
    const fontColor = theme === "dark" ? "rgb(231 229 228)" : "black";
    const headingColor = theme === "dark" ? "#E7EBF1" : "#584a89";
    // const nogginWhiteLogo = require("../../img/NogginWhiteLogo.PNG")
    const nogginWhiteLogo = require("../../img/Asset 5.png");
    const emailLogo = require("../../img/emailLogo.png");
    const locationLogo = require("../../img/location-sharp.png");
    const instaLogo = require("../../img/InstagramLogo.png");
    const facebookLogo = require("../../img/facebookLogo.png");
    const linkedinLogo = require("../../img/linkedInLogo.png");
    const currentYear = new Date().getFullYear();

    return (
        <>
            <style>
                {`.pageContent{
              word-break: break-word;
              color : ${fontColor}
            }`}
            </style>
            <div
                style={{
                    width: "100%",
                    backgroundColor: "#584a89",
                    height: "auto",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    paddingTop: "30px"
                }}
                className="flex flex-col sm:flex-row"
            >
                <div className="w-full flex-initial">
                    <img src={nogginWhiteLogo}></img>

                    <p
                        className="text-sm mb-4 mt-5"
                        style={{ color: "#f2f2f2", fontFamily: "SF Pro" }}
                    >
                        Data is knowledge
                        <br /> and knowledge is power
                    </p>
                    <div className="flex flex-row">
                        <img
                            src={emailLogo}
                            style={{
                                width: 20,
                                height: 14,
                                marginTop: "4px",
                                marginRight: "2px"
                            }}
                        ></img>
                        <a
                            href="mailto:hello@noggin.ai"
                            style={{ fontFamily: "SF Pro", color: "#f2f2f2" }}
                            className="ml-2"
                        >
                            <p>hello@noggin.ai</p>
                        </a>
                    </div>
                    <div style={{ height: "20px" }}></div>
                    <div className="flex flex-row">
                        <img
                            src={locationLogo}
                            style={{
                                width: 17,
                                height: 24,
                                marginTop: "10px",
                                marginRight: "2px"
                            }}
                        ></img>
                        <p
                            className="text-sm mb-4 ml-2"
                            style={{ color: "#f2f2f2", fontFamily: "SF Pro" }}
                        >
                            77 High Street, #02-13, High Street Plaza
                            <br />
                            Singapore 179433
                        </p>
                    </div>
                </div>
                <div className="w-full flex-initial md:mt-6 mt-4 md:ml-12 lg:ml-[200px] sm:ml-20 text-left ">
                    <div className="flex flex-row w-full">
                        <div className="flex flex-col">
                            <p
                                style={{
                                    fontFamily: "SF Pro Bold",
                                    color: "#f2f2f2"
                                }}
                            >
                                Home
                            </p>
                            <div style={{ height: "20px" }}></div>
                            <HashLink
                                to="/#why-noggin"
                                smooth={true}
                                scroll={(el) =>
                                    el.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            >
                                <p
                                    classname="cursor-pointer"
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    Why Noggin
                                </p>
                            </HashLink>
                            <HashLink
                                to="/#who-we-help"
                                smooth={true}
                                scroll={(el) =>
                                    el.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end"
                                    })
                                }
                            >
                                <p
                                    classname="cursor-pointer"
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    Whom we've help?
                                </p>
                            </HashLink>
                            <HashLink
                                to="/#who-we-work-with"
                                smooth={true}
                                scroll={(el) =>
                                    el.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end"
                                    })
                                }
                            >
                                <p
                                    classname="cursor-pointer"
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    Whom we've work with
                                </p>
                            </HashLink>

                            <div style={{ height: "20px" }}></div>
                            <p
                                style={{
                                    fontFamily: "SF Pro Bold",
                                    color: "#f2f2f2"
                                }}
                            >
                                Services
                            </p>
                            <div style={{ height: "20px" }}></div>
                            <Link to="/business">
                                <p
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    Noggin For Business
                                </p>
                            </Link>
                            <Link to="/developers">
                                <p
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    Noggin For Developers
                                </p>
                            </Link>
                            <Link to="#">
                                <p
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    Noggin For Users
                                </p>
                            </Link>
                            <div style={{ height: "20px" }}></div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex-initial md:mt-6 mt-4 md:ml-12 sm:ml-10 text-left ">
                    <div className="flex flex-row w-full">
                        <div className="flex flex-col">
                            <p
                                style={{
                                    fontFamily: "SF Pro Bold",
                                    color: "#f2f2f2"
                                }}
                            >
                                About Us
                            </p>
                            <div style={{ height: "20px" }}></div>
                            <HashLink
                                to="/about-us/#about-noggin"
                                smooth={true}
                                scroll={(el) =>
                                    el.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end"
                                    })
                                }
                            >
                                <p
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    About Noggin
                                </p>
                            </HashLink>
                            <HashLink
                                to="/about-us/#people-of-noggin"
                                smooth={true}
                                scroll={(el) =>
                                    el.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            >
                                <p
                                    style={{
                                        fontFamily: "SF Pro",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    The Leaders Of Noggin
                                </p>
                            </HashLink>

                            <div style={{ height: "45px" }}></div>
                            <Link to="/contact-us">
                                <p
                                    style={{
                                        fontFamily: "SF Pro Bold",
                                        color: "#f2f2f2"
                                    }}
                                >
                                    Contact Us
                                </p>
                            </Link>
                            <div style={{ height: "20px" }}></div>
                            <div style={{ height: "20px" }}></div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex-initial md:mt-6 mt-4 mb-6 md:ml-12 sm:ml-10 text-left ">
                    <div style={{ height: "50%" }}>
                        <h1
                            className="text-md"
                            style={{ fontFamily: "SF Pro", color: "#F2f2f2" }}
                        >
                            Connect With Us!
                        </h1>
                        <div className="flex flex-row pt-2">
                            <a
                                href="https://www.facebook.com/Ezsofe/"
                                target="_blank"
                            >
                                <img
                                    src={facebookLogo}
                                    style={{
                                        width: 30,
                                        height: 25,
                                        paddingRight: 5
                                    }}
                                ></img>
                            </a>
                            <div style={{ width: "5%" }}></div>
                            <a
                                href="https://www.instagram.com/"
                                target="_blank"
                            >
                                <img
                                    src={instaLogo}
                                    style={{
                                        width: 30,
                                        height: 25,
                                        paddingRight: 5
                                    }}
                                ></img>
                            </a>
                            <div style={{ width: "5%" }}></div>
                            <a
                                href="https://sg.linkedin.com/company/nogginai"
                                target="_blank"
                            >
                                <img
                                    src={linkedinLogo}
                                    style={{
                                        width: 30,
                                        height: 25,
                                        paddingRight: 5
                                    }}
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <Media query= {{minWidth:640}}>
            <>
            <div className="w-full sm:w-3/12 flex-initial mt-10 sm:mt-0 sm:ml-12 text-center sm:text-left ">
                      <div className="flex flex-row w-full">
            <div className="flex flex-col">
              <img src={emailLogo} style={{width:20,height:14,marginTop:"4px",marginRight:"2px"}}></img>
              <div style={{height:"20px"}}></div>
              <img src={locationLogo} style={{width:15,height:15,marginTop:"10px",marginRight:"2px"}}></img>
            </div>
            
            <div className="flex flex-col">
              <a href ="mailto:hello@noggin.ai" style={{fontFamily:"SF Pro", color:"#f2f2f2"}}><p>hello@noggin.ai</p></a>
              <div style={{height:"20px"}}></div>
              <p className="text-sm mb-4" style={{color:"#f2f2f2", fontFamily:"SF Pro"}}>25 Mandai Estate Innovation Place, Tower 1<br/>Singapore 729930</p>
            </div>
            
          </div> 
          </div>
          <div className="w-full sm:w-3/12 flex-initial mt-10 sm:mt-0 sm:ml-12 text-center sm:text-left">
  

            <div style ={{height:"50%"}}>
            <h1 className="text-md" style={{fontFamily:"SF Pro Bold", color:"#F2f2f2"}}><u>Social</u></h1>
              <div className="flex flex-row pt-2">
              <a href ="https://www.facebook.com/Ezsofe/" className="mr-3"><img src={facebookLogo} style={{width:30,height:25,paddingRight:5}} target="_blank"></img></a>
              <img className="ml-2 mr-2 mx-3" src={instaLogo} style={{width:30,height:25,paddingRight:5}}></img>
              <a href="https://sg.linkedin.com/company/nogginai" target="_blank" className="mx-3"><img src={linkedinLogo} style={{width:30,height:25,paddingRight:5}}></img></a>
              </div>
            </div>
          </div>
          </>   
          </Media> */}
            </div>
            <div
                style={{
                    color: "#f2f2f2",
                    backgroundColor: "#1c1c1c",
                    width: "100%",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    fontFamily: "SF Pro",
                    paddingTop: "1%",
                    paddingBottom: "1%"
                }}
                className="text-sm"
            >
                Built with passion, Noggin @2014-{currentYear}
            </div>
        </>
    );
};

export default Footer;
